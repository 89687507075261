
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRoot, useStore } from "@libraryHelpers/compositionApi";
import { useLoadComponent } from "@libraryComposables/useLoadComponent";

export default {
	name: "CoreBlockHero",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
		dataSite: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();
		const { state } = store;
		const currentSlide = ref(0);
		const slidesContainer = ref(null);
		const slide = ref(null);
		const slideWidth = ref(null);
		const autoSlide = ref(null);
		const lastSlideChangeTime = ref(0);
		const throttleDuration = ref(1000);
		const root = useRoot();
		const { componentModule: contactCardModule } = useLoadComponent({
			...props,
			settings: { type: "contact-card" },
		});

		const carouselSlides = computed(() => {
			const heroBackgroundMediaSrc =
				props.settings?.heroBackgroundMediaSrc?.filter(
					(image) => image.src && image.src !== ""
				) || [];

			const contentLibraryItems =
				props.settings?.contentLibraryItems || [];

			// put the contentLibraryItem matching the media in the heroBackgroundMediaSrc array,
			// so that the CoreBlockHeroBackground component can use it for expiration
			return heroBackgroundMediaSrc.map((heroItem) => {
				const matchingItem = contentLibraryItems.find(
					(item) => item.localItemId === heroItem.localItemId
				);

				if (!state?.settings?.featureFlags?.VideoAutoPlay)
					return { ...heroItem, contentLibraryItems: [matchingItem] };

				const autoPlay = props.settings?.autoPlay;
				const loop = props.settings?.loop;
				return {
					...heroItem,
					contentLibraryItems: [matchingItem],
					autoPlay,
					loop,
				};
			});
		});

		const applyOverlay = computed(() => {
			return (
				!!props.settings?.variants?.overlay ||
				!!props.settings?.variants?.gradient
			);
		});

		const overlayClasses = computed(() => {
			if (
				!props.settings?.variants?.overlay &&
				!props.settings?.variants?.gradient
			)
				return false;

			return {
				"slide-overlay": props.settings?.variants?.overlay,
				"slide-gradient": props.settings?.variants?.gradient,
			};
		});

		const overlay = computed(() => {
			return {
				enabled: applyOverlay.value,
				classes: overlayClasses.value,
			};
		});

		const componentType = computed(() => {
			return props.settings?.component || "content-card";
		});

		const displayCarouselControls = computed(() => {
			return carouselSlides.value.length > 1;
		});

		const autoSlideEnabled = computed(() => {
			return (
				carouselSlides.value.length > 1 &&
				+props.settings.autoSlide &&
				!props.isEditable
			);
		});

		const fadeComponent = () => {
			root.$el.style.opacity = 1;
		};

		const startAutoSlideTimer = () => {
			autoSlide.value = setInterval(() => {
				nextSlide();
			}, +props.settings.autoSlide * 1000);
		};

		const restartAutoSlide = () => {
			clearInterval(autoSlide);
			startAutoSlideTimer();
		};

		const canChangeSlide = () => {
			const now = Date.now();
			if (now - lastSlideChangeTime.value < throttleDuration.value) {
				return false;
			}
			lastSlideChangeTime.value = now;
			return true;
		};

		const nextSlide = () => {
			if (!canChangeSlide()) {
				return;
			}
			slideWidth.value = slide.value?.clientWidth;
			if (currentSlide.value + 1 < carouselSlides.value.length) {
				slidesContainer.value.scrollLeft += slideWidth.value;
				currentSlide.value++;
			} else {
				slidesContainer.value.scrollLeft -=
					slideWidth.value * carouselSlides.value.length - 1;
				currentSlide.value = 0;
			}
			if (autoSlideEnabled.value) restartAutoSlide();
		};

		const previousSlide = () => {
			if (!canChangeSlide()) {
				return;
			}
			slideWidth.value = slide.value?.clientWidth;
			if (currentSlide.value - 1 >= 0) {
				slidesContainer.value.scrollLeft -= slideWidth.value;
				currentSlide.value--;
			} else {
				slidesContainer.value.scrollLeft +=
					slideWidth.value * carouselSlides.value.length - 1;
				currentSlide.value = carouselSlides.value.length - 1;
			}
			if (autoSlideEnabled.value) restartAutoSlide();
		};

		const goToSlide = (i) => {
			if (!canChangeSlide()) {
				return;
			}
			slideWidth.value = slide.value?.clientWidth;
			if (currentSlide.value !== i) {
				if (currentSlide.value > i) {
					slidesContainer.value.scrollLeft -=
						slideWidth.value * (currentSlide.value - i);
					currentSlide.value = i;
				} else {
					slidesContainer.value.scrollLeft +=
						slideWidth.value * (i - currentSlide.value);
					currentSlide.value = i;
				}
				if (autoSlideEnabled.value) restartAutoSlide();
			}
		};

		onMounted(() => {
			if (carouselSlides.value.length > 0) {
				slidesContainer.value =
					root.$el?.querySelector("#slides-container");
				slide.value = root.$el?.querySelector(".slide");
			} else {
				fadeComponent();
			}
			if (autoSlideEnabled.value) startAutoSlideTimer();
		});

		onBeforeUnmount(() => {
			clearInterval(autoSlide);
		});

		return {
			autoSlide,
			carouselSlides,
			componentType,
			contactCardModule,
			currentSlide,
			displayCarouselControls,
			fadeComponent,
			goToSlide,
			nextSlide,
			overlay,
			previousSlide,
			slide,
			slidesContainer,
			slideWidth,
		};
	},
};
