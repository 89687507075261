
import { ref, computed, watch, onMounted } from "vue";
import mediaHelper from "@libraryHelpers/media.js";
import { useMedia } from "@libraryComposables/useMedia";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import { useRoot, useStore } from "@libraryHelpers/compositionApi";

export default {
	name: "CoreBlockHero",
	props: {
		slide: {
			type: Object,
			default: () => ({
				src: "",
				focal: {},
			}),
		},
		slideIndex: {
			type: Number,
			default: null,
		},
		currentSlide: {
			type: Number,
			default: 0,
		},
		componentType: {
			type: String,
			default: "contact-card",
		},
		overlay: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const store = useStore();
		const { state } = store;
		const croppedImage = ref(null);
		const videoPaused = ref(true);
		const itemCheckFunction = (item) =>
			item.propertyKey === "heroBackgroundMediaSrc";
		const { isExpired } = useIsExpired(props.slide, itemCheckFunction);
		const mediaSrc = computed(() => props.slide?.src);
		const {
			isEMoney: isEMoneyUrl,
			isImage: mediaIsImg,
			isUploadedVideo,
			isVideo,
		} = useMedia(mediaSrc);
		const root = useRoot();
		const videoTemplateRef = ref(null);
		// create video computed const that references the video element in the template
		const video = computed(() => {
			return root.$refs?.videoTemplateRef?.$el?.querySelector("video");
		});

		const videoAutoPlayFF = state?.settings?.featureFlags?.VideoAutoPlay;

		const computedVideoObject = computed(() => {
			if (!videoAutoPlayFF) return { src: props.slide?.src };

			return {
				src: props.slide?.src,
				autoPlay: props.slide?.autoPlay,
				loop: props.slide?.loop,
			};
		});

		const staticVideoClass = {
			"hosted-video-container_eMoney": isEMoneyUrl,
		};

		const computedVideoBind = computed(() => {
			if (!videoAutoPlayFF) {
				return {
					...(props.slideIndex === 0 && { autoplay: true }),
					muted: true,
					loop: true,
					playsinline: true,
				};
			}

			return {
				muted: true,
				playsinline: true,
			};
		});

		const background = computed(() => {
			return {
				"background-image": `url(${
					croppedImage.value ||
					mediaHelper.getEncodedURI(props.slide?.src)
				})`,
				"background-position": props.slide?.focal?.bgPosition,
				"background-repeat": "no-repeat",
				"background-size": "cover",
				height: "inherit",
			};
		});

		const toggleVideoStatus = () => {
			if (video.value) {
				video.value.paused ? video.value.play() : video.value.pause();
				videoPaused.value = video.value.paused;
			}
		};

		watch(
			() => props.currentSlide,
			(nv) => {
				if (!mediaIsImg.value && isUploadedVideo.value) {
					if (nv == props.slideIndex) {
						video.value.play();
						videoPaused.value = false;
					} else {
						video.value.pause();
						videoPaused.value = true;
					}
				}
			}
		);

		onMounted(() => {
			if (mediaIsImg.value || !isUploadedVideo.value || isEMoneyUrl.value)
				emitBackgroundReady();
			else {
				video.value.addEventListener("canplay", emitBackgroundReady);
			}
			if (
				!mediaIsImg.value &&
				isUploadedVideo.value &&
				props.slideIndex === 0
			)
				videoPaused.value = false;
		});

		const emitBackgroundReady = () => {
			root.$emit("backgroundReady");
		};

		const applyOverlay = computed(() => {
			if (isVideo.value) return false;

			return props.overlay?.enabled ?? false;
		});

		return {
			croppedImage,
			videoPaused,
			isExpired,
			isEMoneyUrl,
			mediaIsImg,
			isUploadedVideo,
			videoTemplateRef,
			background,
			toggleVideoStatus,
			computedVideoObject,
			computedVideoBind,
			staticVideoClass,
			applyOverlay,
		};
	},
};
