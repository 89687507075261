import { render, staticRenderFns } from "./CoreBlockHeroBackground.vue?vue&type=template&id=732cc216"
import script from "./CoreBlockHeroBackground.vue?vue&type=script&lang=js"
export * from "./CoreBlockHeroBackground.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockImageCropped: require('/usr/src/app/Core.Library/src/components/block/CoreBlockImageCropped.vue').default,CoreBlockVideo: require('/usr/src/app/Core.Library/src/components/block/CoreBlockVideo.vue').default,CoreIcon: require('/usr/src/app/Core.Library/src/components/icon/CoreIcon.vue').default})
